
import { Component, Input, OnInit } from '@angular/core';
import { WaiverDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';

@Component({
  selector: 'gms-waiver-details',
  templateUrl: './waiver-details.component.html',
})
export class WaiverDetailsComponent implements OnInit {

  @Input() waiver: WaiverDto = new WaiverDto();
  constructor(private waiverService: WaiverService) {
  }

  async ngOnInit() {
  }

  openFile = (file: string) => this.waiverService.waiverFileOpen(this.waiver.projectId, this.waiver.waiverId, file);
  downloadFile = (file: string) => this.waiverService.waiverFileDownload(this.waiver.projectId, this.waiver.waiverId, file);

}

