<blockable-div #container>
  @if(form){
  <gms-waiver-header [waiver]="waiver" class="mt-4"></gms-waiver-header>
  <form [formGroup]="form">
    <div class="grid">
      <div class="col-6">
        <gms-waiver-details [waiver]="waiver"></gms-waiver-details>
      </div>
      <div class="col-6">
        <div class="mb-3 text-xl">Risk Acceptance</div>
        <div class="field-checkbox flex-wrap">
          <p-checkbox formControlName="riskAcceptorAuthorizedToAccept" [binary]="true" inputId="authorized"></p-checkbox>
          <label class="ml-2" for="authorized">I am Authorized to accept Risk on behalf of my project *</label>
        </div>
        <div class="mb-3">
          <label for="description" class="block text-900 font-medium mb-2">Risk Acceptance Remarks *</label>
          <textarea id="description" rows="7" cols="30" pTextarea formControlName="riskAcceptanceRemarks" class="w-full"
                    placeholder="Please list out what you are accepting and how you will take care of risk, these comments will be recorder for further Audits"></textarea>
        </div>
        @if(waiver.details?.securityAssessmentRequired??false){
        <div class="field-checkbox flex-wrap">
          <p-checkbox formControlName="riskAcceptorConfirmsSecurityAssessmentIsDone" [binary]="true" inputId="securityDone"></p-checkbox>
          <label class="ml-2" for="securityDone">I confirm that required security assessment is done and can be produced, when requested *</label>
        </div>
        }

        <div class="field mb-3">
          <!--<label class="block text-900 font-medium mb-2">Documents</label>-->
          @for(file of model.waiverFiles; track file)
          {
          <div class="flex justify-content-between">
            <a class="text-overflow-ellipsis">
              <i class="pi pi-download mr-2 cursor-pointer" (click)="downloadFile(file)"></i>
              <i class="pi pi-external-link mr-2 cursor-pointer" (click)="openFile(file)"></i>{{file}}
            </a>
            <p-button icon="pi pi-times" [link]="true" size="small" (click)="removeFile(file)"></p-button>
          </div>
          }
          <p-fileUpload #fileUpload
                        name="files[]"
                        [customUpload]="true"
                        [multiple]="true"
                        accept="image/*,application/pdf"
                        [maxFileSize]="10000000"
                        [auto]="false"
                        mode="advanced"
                        chooseStyleClass="p-button-text p-button-sm"
                        chooseLabel="Upload Documents"
                        showUploadButton="false"
                        showCancelButton="false">
          </p-fileUpload>
        </div>
        <div class="text-right">
          <p-button label="Cancel" icon="pi pi-times" class="m-3" [outlined]="true" (click)="cancel()"></p-button>
          <p-button label="I accept the risk" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
        </div>
      </div>
    </div>
    <p-blockUI [target]="container" [blocked]="formBlocked">
      <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
    </p-blockUI>
  </form>
  }
  @else{
  <div class="flex justify-content-center m-8">
    <i class="pi pi-rotate pi-microchip text-blue-900" style="font-size: 3rem"></i>
  </div>
  }
</blockable-div>
