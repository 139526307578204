
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { WaiverDto } from '../api/waiver.model';

@Component({
  selector: 'gms-waiver-header',
  templateUrl: './waiver-header.component.html',
  providers: [ConfirmationService]
})
export class WaiverHeaderComponent implements OnInit {

  @Input() waiver: WaiverDto = new WaiverDto();
  constructor() {
  }

  async ngOnInit() {
  }
}

