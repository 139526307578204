
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WaiverDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';
import { lastValueFrom } from 'rxjs';

@Component({
  templateUrl: './waiver-info-dialog.component.html',
  providers: [ConfirmationService]
})
export class WaiverInfoDialogComponent implements OnInit {

  waiverId!: string;
  projectId!: string;

  waiver: WaiverDto = new WaiverDto();
  constructor(
    private waiverService: WaiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
    this.waiverId = this.config.data.waiverId;
    this.projectId = this.config.data.projectId;
  }

  async ngOnInit() {
    this.waiver = await lastValueFrom(this.waiverService.getWaiverById(this.projectId, this.waiverId));
  }

  cancel() {
    this.ref.close();
  }
}

