
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { NotificationService } from '../../../../shared/services/notification.service';
import { formAddValidators } from '../../../../shared/utils';
import { WaiverAcceptRiskDto, WaiverDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
  templateUrl: './waiver-acceptrisk-dialog.component.html',
  providers: [ConfirmationService],
  styles: [`
  ::ng-deep .p-fileupload .p-fileupload-buttonbar {padding:0; background:none; border:none;}
  ::ng-deep .p-fileupload-content .p-fileupload-row > * { width:15%;display:inline-block; vertical-align:middle;}
  ::ng-deep .p-fileupload-content {padding:0; border:none;}
  ::ng-deep .p-fileupload-content .p-fileupload-filename {width:55%;}
  ::ng-deep .p-fileupload-content .p-fileupload-row {display:block; text-align:right;}
  `]
})
export class WaiverAcceptRiskDialogComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  waiverId!: string;
  projectId!: string;

  waiver: WaiverDto = new WaiverDto();

  model: WaiverAcceptRiskDto = new WaiverAcceptRiskDto();

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  constructor(
    private fb: FormBuilder,
    private waiverService: WaiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService
  ) {
    this.waiverId = this.config.data.waiverId;
    this.projectId = this.config.data.projectId;
  }

  async ngOnInit() {
    this.validators = await lastValueFrom(this.waiverService.getValidator('accept-risk'));
    this.form = formAddValidators(this.fb.group(this.model), this.validators);

    this.waiverService.getWaiverById(this.projectId, this.waiverId)
      .subscribe(q => {
        this.waiver = q;
        this.model.waiverFiles = q.details?.waiverFiles ?? [];
        if (!(this.waiver.details?.securityAssessmentRequired??false)) {
          this.form?.get('riskAcceptorConfirmsSecurityAssessmentIsDone')?.clearValidators();
          this.form?.get('riskAcceptorConfirmsSecurityAssessmentIsDone')?.updateValueAndValidity();
        }
      });
  }

  cancel() {
    this.ref.close();
  }

  removeFile(file: string) {
    this.model.waiverFiles = this.model.waiverFiles?.filter(f => f !== file);
  }

  openFile = (file: string) => this.waiverService.waiverFileOpen(this.projectId, this.waiverId, file);
  downloadFile = (file: string) => this.waiverService.waiverFileDownload(this.projectId, this.waiverId, file);

  save() {
    this.formBlocked = true;
    const model = this.form?.value;
    model.waiverFiles = this.model.waiverFiles;
    this.waiverService.
      acceptRiskWaiver(this.projectId, this.waiverId, model)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Saved')

            if (this.fileUpload.files.length) {
              const formData = new FormData();
              for (let file of this.fileUpload.files) {
                formData.append('files', file, file.name);
              }
              this.waiverService.waiverFilesUpload(this.projectId, r.waiverId, formData)
                .subscribe({
                  next: (upl) => {
                    this.notify.success(upl.message);
                    this.ref.close(r)
                  },
                  error: e => this.notify.error(e)
                });

            } else {
              this.ref.close(r);
            }
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
  }
}
