<blockable-div #container>
  @if(form){
  <form [formGroup]="form" class="mt-4">
    <div class="grid">
      <div class="col-6">
        <div class="field mb-3">
          <label for="projectId" class="block text-900 font-medium mb-2">Project *</label>
          <p-dropdown [options]="projects" formControlName="projectId" placeholder="Project" optionLabel="projectName" optionValue="projectId"
                      [editable]="false" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
        </div>
        <div class="field mb-3">
          <label for="ticketNumber" class="block text-900 font-medium mb-2">Ticket Number *</label>
          <input id="ticketNumber" formControlName="ticketNumber" type="text" pInputText placeholder="Ticket Number" class="w-full">
        </div>
        <div class="field mb-3">
          <label for="description" class="block text-900 font-medium mb-2">Why the waiver is required? *</label>
          <textarea rows="5" cols="30" pTextarea formControlName="description" class="w-full"></textarea>
        </div>
        <div class="field mb-3">
          <label for="environmentType" class="block text-900 font-medium mb-2">Environment Type where Waiver is required? *</label>
          <p-dropdown [options]="environments" formControlName="environmentType" placeholder="Environment" optionLabel="label" optionValue="value"
                      [editable]="false" appendTo="body" id="environmentType" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
        </div>
        <div class="field mb-3">
          <label for="durationMonths" class="block text-900 font-medium mb-2">For how long Waiver is required? *</label>
          <p-dropdown [options]="durations" formControlName="durationMonths" placeholder="Duration In Months" optionLabel="label" optionValue="value"
                      [editable]="false" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
        </div>
      </div>
      <div class="col-6">
        <div class="field mb-3">
          <label for="policies" class="block text-900 font-medium mb-2">Waiver required for? * <small>Select from the list or write custom</small></label>
          <p-dropdown [options]="policies" formControlName="policyDescription" placeholder="Policy" optionLabel="label" optionValue="label"
                      [editable]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full" (onChange)="policyChanged($event)"></p-dropdown>
        </div>
        <div class="field mb-3">
          <label for="resourceGroupName" class="block text-900 font-medium mb-2">Enter Resource Group Name where the Waiver is required *</label>
          <input id="resourceGroupName" formControlName="resourceGroupName" type="text" pInputText placeholder="Resource Group Name" class="w-full">
        </div>
        <div class="field mb-3">
          <label for="subscriptionId" class="block text-900 font-medium mb-2">Enter Subscription Id where resource group is present *</label>
          <input id="subscriptionId" formControlName="subscriptionId" type="text" pInputText placeholder="Subscription Id" class="w-full">
        </div>
        <div class="field mb-3">
          <!--<label class="block text-900 font-medium mb-2">Documents</label>-->
          @for(file of model.waiverFiles; track file)
          {
          <div class="flex justify-content-between">
            <a class="text-overflow-ellipsis">
              <i class="pi pi-download mr-2 cursor-pointer" (click)="downloadFile(file)"></i>
              <i class="pi pi-external-link mr-2 cursor-pointer" (click)="openFile(file)"></i>{{file}}
            </a>
            <p-button icon="pi pi-times" [link]="true" size="small" (click)="removeFile(file)"></p-button>
          </div>
          }
          <p-fileUpload #fileUpload
                        name="files[]"
                        [customUpload]="true"
                        [multiple]="true"
                        accept="image/*,application/pdf"
                        [maxFileSize]="10000000"
                        [auto]="false"
                        mode="advanced"
                        chooseStyleClass="p-button-text p-button-sm"
                        chooseLabel="Upload Documents"
                        showUploadButton="false"
                        showCancelButton="false">
          </p-fileUpload>
        </div>
       </div>
      </div>
    <div class="text-right">
      <p-button label="Cancel" icon="pi pi-times" class="m-3" [outlined]="true" (click)="cancel()"></p-button>
      <p-button label="Submit request for review" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
    </div>
    <p-blockUI [target]="container" [blocked]="formBlocked">
      <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
    </p-blockUI>
  </form>
  }
  @else{
  <div class="flex justify-content-center m-8">
    <i class="pi pi-rotate pi-microchip text-blue-900" style="font-size: 3rem"></i>
  </div>
  }
</blockable-div>

