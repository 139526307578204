
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../../shared/services/_base-api.service';
import { PermissionDictionary, TenantDto } from './configuration.model';
import { GmsStore } from '../gms.store';
import { ValidationRule } from '../../../shared/models/validation-rule';
import { GmsConfigService } from '../gms-configuration.service';

export const GmsConfigurationApiUrl = {
  ConfigurationTenants: () => "/configuration/tenants",
  ConfigurationUserRoles: () => "/configuration/roles",
  ConfigurationValidators: () => "/configuration/validators",
  ConfigurationVariableList: () => "/configuration/variables",
  ConfigurationPermissions: () => "/configuration/permissions"
}

@Injectable({providedIn: 'root'})
export class ConfigurationService extends BaseApiService{
    
  constructor(config: GmsConfigService, store: GmsStore) {
    super(config, store);
  }

  //#region Configuration

  tenants(): Observable<TenantDto[]> {
    return super.get<TenantDto[]>(GmsConfigurationApiUrl.ConfigurationTenants());
  }

  roles(): Observable<string[]> {
    return super.get<string[]>(GmsConfigurationApiUrl.ConfigurationUserRoles());
  }

  validators(endpoint: string): Observable<ValidationRule[]> {
    return super.get<ValidationRule[]>(GmsConfigurationApiUrl.ConfigurationValidators(), { endpoint });
  }

  getPermissions(): Observable<PermissionDictionary> {
    return super.get<PermissionDictionary>(GmsConfigurationApiUrl.ConfigurationPermissions());
  }

  //#endregion
}
