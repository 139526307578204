import { WaiverState } from "./waiver-enums.model";

export class WaiverListFilter {
  projectId: string | null = '';
  tenant: string | null = '';
  states: WaiverState[] = [];
  myRequests: boolean = true;

  search: string | null = null;

  constructor(init?: Partial<WaiverListFilter>) {
    Object.assign(this, init);
  }
}
