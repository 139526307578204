import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaiverListComponent } from './components/waiver-list.component';

const routes: Routes = [
  { path: 'list', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: WaiverListComponent },
//  { path: 'edit/:id', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: FirewallRequestEditComponent },
//  { path: 'create', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: FirewallRequestEditComponent },
//  {
//    path: 'requests',
//    redirectTo: 'requests/list',
//    pathMatch: 'full'
//  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaiverRoutingModule { }
