
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GmsStore } from '../gms.store';
import { BaseApiService } from '../../../shared/services/_base-api.service';
import { ValidationRule } from '../../../shared/models/validation-rule';
import { GmsConfigurationApiUrl } from './configuration.service';
import { ProjectDto } from './project.model';
import { GmsConfigService } from '../gms-configuration.service';

export const GmsProjectApiUrl = {
  ProjectGetList: () => "/projects",
  ProjectGetById: (id:string) => `/projects/${id}`,
}

@Injectable({providedIn: 'root'})
export class ProjectService extends BaseApiService{

  constructor(config: GmsConfigService, store: GmsStore) {
    super(config, store);
  }

  getValidators(method: keyof typeof GmsProjectApiUrl) {
    return super.get<ValidationRule[]>(GmsConfigurationApiUrl.ConfigurationValidators(), { method });
  }

  //#region Project Profiles

  projects(): Observable<ProjectDto[]> {
    return super.get<ProjectDto[]>(GmsProjectApiUrl.ProjectGetList());
  }

  projectProfileGet(id: string): Observable<ProjectDto> {
    return super.get(GmsProjectApiUrl.ProjectGetById(id));
  }

  //#endregion  
}
