<blockable-div #container>
  @if(form){
  <gms-waiver-header [waiver]="waiver" class="mt-4"></gms-waiver-header>
  <form [formGroup]="form">
    <div class="grid">
      <div class="col-6">
        <gms-waiver-details [waiver]="waiver"></gms-waiver-details>
      </div>
      <div class="col-6">
        <div class="mb-3 text-xl">Admin Team Inputs</div>
        <div class="grid grid-nogutter mb-3">
          <label for="projectId" class="block text-900 font-medium mb-2 col-10">Does Risk Assessment required for this request? *</label>
          <div class="col-2">
            <p-inputSwitch formControlName="riskAssessmentRequired" (onChange)="riskAssessmentChanged($event)" />
          </div>
        </div>
        @if(form.get('riskAssessmentRequired')?.value??false){
        <div>
          <label for="description" class="block text-900 font-medium mb-2">Risk Assessments inputs for requestor *</label>
          <textarea id="description" rows="7" cols="30" pTextarea formControlName="riskAssessmentDescription" class="w-full mb-3"
                    placeholder="These comments will be visible to requestor, which will explain him/her what they have to accept as risk"></textarea>
        </div>
        <div class="grid grid-nogutter mb-3">
          <label for="projectId" class="block text-900 font-medium mb-2 col-10">Does IT Security Assessment required for this request? </label>
          <div class="col-2">
            <p-inputSwitch formControlName="securityAssessmentRequired" />
          </div>
        </div>
        }
        <div class="field-checkbox flex-wrap">
          <p-checkbox formControlName="reviewerAcceptsRuleBook" [binary]="true" inputId="ruleBook"></p-checkbox>
          <label for="ruleBook">As reviewer of this request, I acknowledge that I have referred and follow *</label><a target="_blank" href="https://devstack.vwgroup.com/confluence/x/qwCXBgE">Waiver Acceptance<br />RuleBook</a>
        </div>
        <div class="field-checkbox flex-wrap">
          <p-checkbox formControlName="reviewerConfirmsAlignmentsWithArchitects" [binary]="true" inputId="architects"></p-checkbox>
          <label class="ml-2" for="architects">As reviewer of this request, I confirm that I have done required allignments with Architect *</label>
        </div>
      </div>
    </div>
    <div class="text-right mt-4">
      <p-button label="Reject" icon="pi pi-times" class="m-3" severity="warning" (click)="opReject.toggle($event)"></p-button>
      <p-button label="Cancel" icon="pi pi-times" class="m-3" [outlined]="true" (click)="cancel()"></p-button>
      <p-button [label]="(form.get('riskAssessmentRequired')?.value??false) ? 'Save Review' : 'Save Review and Accept Risk'" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
    </div>
    <p-blockUI [target]="container" [blocked]="formBlocked">
      <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
    </p-blockUI>

    <p-overlayPanel #opReject>
      <div class="flex flex-column gap-3 w-25rem">
        <div>
          <label for="reason" class="block text-900 font-medium mb-2">Rejection Reason</label>
          <textarea id="reason" rows="5" cols="30" pTextarea formControlName="rejectionReason" class="w-full mb-3"
                    placeholder="Please describe rejection reason"></textarea>
        </div>
        <div class="text-right">
          <p-button label="Reject Waiver" icon="pi pi-times" class="m-3" severity="warning" [disabled]="!form.get('rejectionReason')?.value" (click)="reject()"></p-button>
        </div>
      </div>
    </p-overlayPanel>

  </form>
  }
  @else{
  <div class="flex justify-content-center m-8">
    <i class="pi pi-rotate pi-microchip text-blue-900" style="font-size: 3rem"></i>
  </div>
  }
</blockable-div>
