
<div class="flex mt-2 mb-2 justify-content-between">
  <span class="text-4xl">
    Waivers
    <sup class="p-badge p-badge-info">{{items.length}}</sup>
  </span>
</div>

<div class="flex mt-2 mb-2 justify-content-around" [formGroup]="filters">
  <div class="flex-1 mr-2">
    <p-toggleButton formControlName="myRequests" onLabel="My Requests" offLabel="All Requests" styleClass="w-full"></p-toggleButton>
  </div>
  <div class="flex-1 mr-2">
    <input type="text" pInputText
           formControlName="search"
           placeholder="Search" class="w-full" />
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterTenants"
                optionLabel="tenantName" optionValue="tenantName"
                formControlName="tenant"
                placeholder="Tenant" [showClear]="true" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterProjects"
                optionLabel="projectName" optionValue="projectId"
                formControlName="projectId"
                [filter]="true" placeholder="Project" [showClear]="true" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
  </div>
  <div class="flex-1 mr-2">
    <p-multiSelect [options]="filterStatuses"
                   formControlName="states"
                   placeholder="State" [filter]="false" [showClear]="true" styleClass="w-full" inputStyleClass="w-full"></p-multiSelect>
  </div>
  <div class="">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="filtersClear(); "></i>
  </div>
  <div class="">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-refresh" (click)="refreshItems();"></i>
  </div>
  <div class="">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-plus" (click)="waiverCreateDialog();"></i>
  </div>

</div>


<!--waiverId: string = '';
tenant: string = '';
waiverType: string = 'Policy';
ticketNumber: string = '';
projectId: string = '';
description: string = '';
durationMonths: number = 0;
environmentType?: EnvironmentType;
policyDescription: string = '';
policyDefinitionId: string = '';
resourceGroupName: string = '';
subscriptionId: string = '';
deploymentId: string = '';
createdAt: Date = new Date();
createdBy: string = '';
expiresAt: Date = new Date();
rejectionReason: string = '';
state?: WaiverState;

createdByUser?: UserDto;-->


<p-table #dt styleClass="p-datatable-sm w-full"
         [value]="filteredItems"
         [rowHover]="true">

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="tenant">Tenant</th>
      <th pSortableColumn="projectId">Project</th>
      <th pSortableColumn="ticketNumber">Ticket</th>
      <th class="text-center" pSortableColumn="durationMonths">Duration</th>
      <th pSortableColumn="environmentType">Environment</th>
      <th pSortableColumn="resourceGroupName">Resource</th>
      <th pSortableColumn="createdAt">Created</th>
      <th pSortableColumn="state">State</th>
      <th class="w-1"></th>
      <th class="w-1"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr class="cursor-pointer" (dblclick)="waiverInfoDialog(item.projectId, item.waiverId)">
      <td>{{item.tenant}}</td>
      <td>{{item.projectId}}</td>
      <td>{{item.ticketNumber}}</td>
      <td class="text-no-wrap text-center">{{item.durationMonths}}M<br />{{item.expiresAt|date:'short'}}</td>
      <td>{{item.environmentType}}</td>
      <td>{{item.resourceGroupName}}<br />{{item.subscriptionId}}</td>
      <td class="text-no-wrap">{{item.createdAt | date:'short'}}<br />{{item.createdByUser.displayName}}</td>
      <td [class]="waiverStateText(item.state).class">
        {{ waiverStateText(item.state).text }}
      </td>
      <td class="text-no-wrap">
        @if(item.state == waiverStates.InReview && priviledgedUser){
        <p-button label="Review" icon="pi pi-clock" size="small" styleClass="p-button-link" (click)="waiverReviewDialog(item.projectId, item.waiverId)" />
        }
        @if(item.state == waiverStates.WaitingRiskAcceptance && userProjects.includes(item.projectId)){
        <p-button label="Accept Risk" icon="pi pi-clock" size="small" styleClass="p-button-link" (click)="waiverAcceptRiskDialog(item.projectId, item.waiverId)" />
        }
        @if(item.state == waiverStates.WaitingCompletion && priviledgedUser){
        <p-button label="Complete" icon="pi pi-clock" size="small" styleClass="p-button-link" (click)="waiverCompleteDialog(item.projectId, item.waiverId)" />
        }
      </td>
      <td class="text-no-wrap text-right">
        @if(userProjects.includes(item.projectId) && item.state == waiverStates.InReview){
        <p-button icon="pi pi-pencil" styleClass="p-button-link" (click)="waiverCreateDialog(item.projectId, item.waiverId)"></p-button>
        }
        <p-button icon="pi pi-info-circle" styleClass="p-button-link" (click)="waiverInfoDialog(item.projectId, item.waiverId)"></p-button>
      </td>
    </tr>
  </ng-template>

</p-table>

@if(items.length==0){
  @if(loading){
    <div class="flex justify-content-center m-8">
      <i class="pi pi-rotate pi-microchip text-blue-900" style="font-size: 3rem"></i>
    </div>
  }
  @else{
    <div class="flex align-items-center justify-content-center">
      <p class="py-8 text-center">
        <b>The list is empty</b><br />
        Please check the filter settings
      </p>
    </div>
    }
  }


<p-confirmDialog></p-confirmDialog>
