@if(waiver){
<div class="m-4">
  <div class="grid grid-nogutter w-full text-center">
    <div class="col-3">
      <i class="pi pi-check-circle text-green-500"></i><br />
      Created<br />
      {{waiver.createdAt | date:'short'}}<br />
      {{waiver.createdByUser?.displayName}}
    </div>
    <div class="col-3">
      @if(waiver.state == 'Rejected'){
      <i class="pi pi-times-circle text-red-500"></i><br />
      }
      @else{
      <i class="pi {{waiver.details?.reviewedBy? 'pi-check-circle text-green-500' : 'pi-clock'}} "></i><br />
      }
      Reviewed<br />
      @if(waiver.details?.reviewedBy){
      {{waiver.details?.reviewedAt | date:'short'}}<br />
      {{waiver.details?.reviewedByUser?.displayName}}
      }
    </div>
    @if(waiver.state != 'Rejected'){
    <div class="col-3">
      <i class="pi {{ waiver.details?.riskAssessmentRequired === false ? 'pi-angle-double-right text-green-500': (waiver.details?.riskAcceptedBy? 'pi-check-circle text-green-500' : 'pi-clock' )}} "></i><br />
      Risk Accepted<br />
      @if(waiver.details?.riskAcceptedBy){
      {{waiver.details?.riskAcceptedAt | date:'short'}}<br />
      {{waiver.details?.riskAcceptedByUser?.displayName}}
      }
    </div>
    <div class="col-3">
      <i class="pi {{waiver.details?.waiverCompletedBy? 'pi-check-circle text-green-500' : 'pi-clock'}} "></i><br />
      Completed<br />
      @if(waiver.details?.waiverCompletedBy){
      {{waiver.details?.waiverCompletedAt | date:'short'}}<br />
      {{waiver.details?.waiverCompletedByUser?.displayName}}
      }
    </div>
    }
  </div>
</div>
}
@else{
<div class="flex justify-content-center">
</div>
}
