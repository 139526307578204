export enum WaiverState {
  Rejected = 'Rejected',
  InReview = 'InReview',
  WaitingRiskAcceptance = 'WaitingRiskAcceptance',
  WaitingCompletion = 'WaitingCompletion',
  Active = 'Active',
  Expired = 'Expired'
}

export enum EnvironmentType {
  Development = 'Development',
  QA = 'QA',
  Staging = 'Staging',
  Production = 'Production'
}
