
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { NotificationService } from '../../../../shared/services/notification.service';
import { formAddValidators } from '../../../../shared/utils';
import { OptionModel, WaiverCompleteDto, WaiverDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';

@Component({
  templateUrl: './waiver-complete-dialog.component.html',
  providers: [ConfirmationService]
})
export class WaiverCompleteDialogComponent implements OnInit {

  waiverId!: string;
  projectId!: string;

  waiver: WaiverDto = new WaiverDto();

  model: WaiverCompleteDto = new WaiverCompleteDto();

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  durations: OptionModel[] = [];

  constructor(
    private fb: FormBuilder,
    private waiverService: WaiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService
  ) {
    this.waiverId = this.config.data.waiverId;
    this.projectId = this.config.data.projectId;
  }

  async ngOnInit() {
    this.validators = await lastValueFrom(this.waiverService.getValidator('complete'));
    this.durations = await lastValueFrom(this.waiverService.getOptions('duration-months'));

    this.waiverService.getWaiverById(this.projectId, this.waiverId)
      .subscribe(q => {
        this.waiver = q;
        this.form = formAddValidators(this.fb.group(this.model), this.validators);
        this.form.patchValue({
          deploymentId: q.deploymentId,
          policyDefinitionId: q.policyDefinitionId,
          durationMonths: q.durationMonths
        });
      });
  }

  cancel() {
    this.ref.close();
  }

  save() {
    this.formBlocked = true;
    this.waiverService.
      completeWaiver(this.projectId, this.waiverId, this.form?.value)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Saved')
            this.ref.close(r);
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
  }
}

