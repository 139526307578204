
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { lastValueFrom } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { NotificationService } from '../../../../shared/services/notification.service';
import { formAddValidators } from '../../../../shared/utils';
import { WaiverDto, WaiverReviewDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';

@Component({
  templateUrl: './waiver-review-dialog.component.html',
  providers: [ConfirmationService]
})
export class WaiverReviewDialogComponent implements OnInit {

  waiverId!: string;
  projectId!: string;

  waiver: WaiverDto = new WaiverDto();

  model: WaiverReviewDto = new WaiverReviewDto();

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  constructor(
    private fb: FormBuilder,
    private waiverService: WaiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService
  ) {
    this.waiverId = this.config.data.waiverId;
    this.projectId = this.config.data.projectId;
  }

  async ngOnInit() {
    this.validators = await lastValueFrom(this.waiverService.getValidator('review'));
    this.waiver = await lastValueFrom(this.waiverService.getWaiverById(this.projectId, this.waiverId))

    this.form = formAddValidators(this.fb.group(this.model), this.validators);
    this.riskAssessmentChanged({ checked: this.model.riskAssessmentRequired } as InputSwitchChangeEvent);
  }

  riskAssessmentChanged(event: InputSwitchChangeEvent) {
    if (event.checked) {
      formAddValidators(this.form!, this.validators.filter(q => q.property == 'riskAssessmentDescription'));
      formAddValidators(this.form!, this.validators.filter(q => q.property == 'securityAssessmentRequired'));
    } else {
      this.form?.get('securityAssessmentRequired')?.clearValidators();
      this.form?.get('riskAssessmentDescription')?.clearValidators();
      this.form?.patchValue({ securityAssessmentRequired: false, riskAssessmentDescription:'' });
    }
    this.form?.get('securityAssessmentRequired')?.updateValueAndValidity();
    this.form?.get('riskAssessmentDescription')?.updateValueAndValidity();
  }

  cancel() {
    this.ref.close();
  }

  reject() {
    this.formBlocked = true;
    //validate?
    this.waiverService.
      reviewWaiver(this.projectId, this.waiverId, this.form?.value)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Rejected')
            this.ref.close(r);
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
  }

  save() {
    this.formBlocked = true;
    this.waiverService.
      reviewWaiver(this.projectId, this.waiverId, this.form?.value)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Saved')
            this.ref.close(r);
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
  }
}

