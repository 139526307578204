
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { forkJoin, lastValueFrom } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { NotificationService } from '../../../../shared/services/notification.service';
import { formAddValidators } from '../../../../shared/utils';
import { ProjectDto } from '../../../services/api/project.model';
import { ProjectService } from '../../../services/api/project.service';
import { GmsStore } from '../../../services/gms.store';
import { enumToOptionsArray } from '../../../services/utils';
import { EnvironmentType } from '../api/waiver-enums.model';
import { OptionModel, WaiverCreateDto, WaiverDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';

@Component({
  templateUrl: './waiver-create-dialog.component.html',
  providers: [ConfirmationService],
  styles: [`
  ::ng-deep .p-fileupload .p-fileupload-buttonbar {padding:0; background:none; border:none;}
  ::ng-deep .p-fileupload-content .p-fileupload-row > * { width:15%;display:inline-block; vertical-align:middle;}
  ::ng-deep .p-fileupload-content {padding:0; border:none;}
  ::ng-deep .p-fileupload-content .p-fileupload-filename {width:55%;}
  ::ng-deep .p-fileupload-content .p-fileupload-row {display:block; text-align:right;}
  `]
})

export class WaiverCreateDialogComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  waiverId!: string;
  projectId!: string;

  model: WaiverCreateDto = new WaiverCreateDto();

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  //waiverFiles: any[] = [];
  projects: ProjectDto[] = [];
  durations: OptionModel[] = [];
  policies: OptionModel[] = [];
  environments: OptionModel[] = enumToOptionsArray(EnvironmentType);

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private waiverService: WaiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService,
    private store: GmsStore
  ) {
    this.waiverId = this.config.data.waiverId;
    this.projectId = this.config.data.projectId;
  }

  async ngOnInit() {
    const user = this.store.current.gmsUser;

    // Create an object to hold all parallel requests
    const requests: any = {
      validators: this.waiverService.getValidator('create'),
      projects: this.projectService.projects(),
      durations: this.waiverService.getOptions('duration-months'),
      policies: this.waiverService.getOptions('policies')
    };

    // Add waiver request conditionally if waiverId exists
    if (this.waiverId) {
      requests.waiver = this.waiverService.getWaiverById(this.projectId, this.waiverId);
    }

    // Execute all requests in parallel and wait for them to complete
    const results = await lastValueFrom(forkJoin(requests)) as any;

    // Set properties from the results
    this.validators = results.validators;
    this.durations = results.durations;
    this.policies = results.policies;
    this.projects = (results.projects as ProjectDto[]).filter(p => user?.memberInProjects.includes(p.projectId));

    // Set model from waiver if available
    if (results.waiver) {
      const waiver = results.waiver as WaiverDto;
      this.model = new WaiverCreateDto({ ...waiver, waiverFiles: waiver.details?.waiverFiles ?? [] });
    }

    this.form = formAddValidators(this.fb.group({ ...this.model, details:null, waiverFiles:null }), this.validators);
  }
  
  policyChanged(event: DropdownChangeEvent) {
    const policy = this.policies.find(t => t.label == event.value);
    this.form?.patchValue({ policyDefinitionId: policy?.value });
  }

  cancel() {
    this.ref.close();
  }

  removeFile(file: string) {
    this.model.waiverFiles = this.model.waiverFiles?.filter(f => f !== file);
  }

  openFile = (file: string) => this.waiverService.waiverFileOpen(this.projectId, this.waiverId, file);
  downloadFile = (file: string) => this.waiverService.waiverFileDownload(this.projectId, this.waiverId, file);

  save() {
    this.formBlocked = true;
    const projectId = this.form?.get('projectId')?.value;
    const model = this.form?.value;
    model.waiverFiles = this.model.waiverFiles;
    this.waiverService.
      createWaiver(projectId, model)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Saved')

            if (this.fileUpload.files.length) {
              const formData = new FormData();
              for (let file of this.fileUpload.files) {
                formData.append('files', file, file.name);
              }
              this.waiverService.waiverFilesUpload(projectId, r.waiverId, formData)
                .subscribe({
                  next: (upl) => {
                    this.notify.success(upl.message);
                    this.ref.close(r)
                  },
                  error: e => this.notify.error(e)
                });

            } else {
              this.ref.close(r);
            }
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
  }

  //onUpload(event: any) {
  //  for (let file of event.files) {
  //    this.waiverFiles.push(file);
  //  }
  //}
}
